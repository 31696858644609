































































































































.btn1,
.btn2 {
	color: #000;
	border: 0;
	font-size: 14px;
	background: none;
	padding: 0;
	margin-right: 0.36rem;
}

/*.yaoQingStyle {*/
/* position: absolute;
            bottom: 1.2rem;
            left: 0rem auto; */
/*margin-top: 11rem;*/
/*}*/

.titlePStyle {
	/* margin-bottom: 0.2rem; */
	margin-top: 0.2rem;
	/* padding-top: 0.1rem; */
	margin-right: 7rem;
	transform: rotate(180deg);
}

.qrcode {
	width: 0.3rem;
}

.fengXiangCode {
	position: absolute;
	bottom: 6.5rem;
	left: 3.2rem;
}

.spanStyle {
	margin-right: 7rem;
}

.op_container {
	flex: 1;
	text-align: left;
	margin-left: 0.5rem;
}

.title {
	// background-image: url('@/assets/imgs/share/backGroundImg2.png');
	background-position: center top;
	background-size: 100% 100%;
	// height: 100%;
	// width: 100%;
	// color: white;
	// text-align: center;
	&.bgVip {
		height: 100%;
		background-image: url('../../assets/imgs/share/poster.png');
	}

	.shareFooter {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 0.4rem 0.6rem;
		display: flex;
		align-items: center;
		background: linear-gradient(180deg, #fefeff 0%, #e6e6ff 100%);

		.codeImgStyle {
			width: 1.29rem;
			height: 1.29rem;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.yaoQingStyle {
			color: #000;
			font-size: 16px;
			margin: 0.15rem 0 0.2rem;
		}

		.rightBtn {
			margin-right: 10px;
		}
	}
}

.footerBtn {
	color: #ffffff;
	font-size: 0.58rem;
	width: 4.24rem;
	height: 1.54rem;
	padding-bottom: 0.14rem;
	border-radius: 0.77rem;
	background: #ff4b04 url(../../assets/imgs/share/VIP.png);
	background-size: 100% 100%;
	margin: 0 auto;
	span {
		font-weight: bold;
	}
}

// --------20200410[start]----------
.layoutBox {
	padding: .1rem .3rem .5rem;
	.shareAds {
		margin-bottom: .1rem;
		img {
			display: block;
			width: 100%;
		}
	}
	.shareSteps {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: .5rem .25rem;
		background: #FFFFFF;
		margin-bottom: .1rem;
		.item {
			font-size: .28rem;
			text-align: center;
			.icon {
				margin-bottom: .1rem;
				img {
					width: .64rem;
					height: .64rem;
					display: block;
					margin: 0 auto;
				}
			}
			p {
				width: 4em;
				&.mtext {
					width: 6em;
				}
			}
		}
		.split {
			width: .32rem;
			height: .3rem;
			background: url(../../assets/imgs/share/arr.png) no-repeat center center;
			background-size: 100% 100%;
		}
	}
	.shareInfo {
		background: #FFFFFF;
		border-radius: .12rem;
		margin-bottom: .1rem;
		.top {
			padding: .25rem;
			border-bottom: 1px solid #EDEDED;
		}
		.bottom {
			padding: .25rem;
			display: flex;
			justify-content: space-between;
			.btn {
				color: white;
				font-size: .32rem;
				text-align: center;
				width: 3.25rem;
				height: .88rem;
				line-height: .88rem;
				background: #93C4FC;
				border-radius: .12rem;
				&.btnR {
					background: #E0C3FC;
					position: relative;
					&::before {
						content: '';
						position: absolute;
						left: -.15rem;
						width: .3rem;
						height: 100%;
						background: #FFFFFF;
						transform: skewX(-15deg);
						transform-origin: 50% 50%;
					}
				}
				
			}
		}
		.item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: .1rem;
			&:last-child {
				margin-bottom: 0;
			}
			.inp {
				// width: 1.8rem;
				height: .7rem;
				line-height: .7rem;
				padding: 0 .1rem 0 .35rem;
				background: #F6F7F9;
				border-radius: .34rem;
				display: flex;
				justify-content: space-between;
				.link {
					width: 3rem;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
			.copy {
				width: .7rem;
				height: .7rem;
				background: url(../../assets/imgs/share/invitation_icon_file.png) no-repeat center center;
				background-size: .4rem .4rem;
				border: 0;
			}
		}
		
	}
	.shareReward {
		padding: .25rem;
		background: #FFFFFF;
		border-radius: .12rem;
		.caption {
			height: .5rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: .15rem;
			h3 {
				font-size: .32rem;
			}
		}
	}
}
.posterLayout {
	position: fixed;
	z-index: 98;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(4, 4, 15, 0.4);
}
.posterCode {
	position: fixed;
	z-index: 99;
	left: .3rem;
	right: .3rem;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	.poster {
		position: relative;
		img {
			display: block;
			width: 100%;
		}
		.code {
			position: absolute;
			left: 50%;
			top: 2.9rem;
			width: 1.8rem;
			height: 1.8rem;
			margin-left: -.9rem;
		}
	}
	.close {
		width: .8rem;
		height: .8rem;
		background: url(../../assets/imgs/share/icon_shut.png) no-repeat center center;
		background-size: .6rem .6rem;
		margin: .5rem auto 0;
	}
}

// --------20200410[start]----------

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;